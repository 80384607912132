import { assertNaturalNumber } from './assert-natural-number';

/**
 *
 * A pairing function takes two positive integers and outputs a single integer that's unique to the inputs. There will
 * always be a pairing and it will always be unique to the two inputs. Order of the inputs is important.
 *
 * @example
 * toPairedNumber(47, 32) // Output: 3192
 * @param arg0 - The first integer of the pairing
 * @param arg1 - The second integer of the pairing
 * @returns A unique integer derived from the two inputs
 * @see https://en.wikipedia.org/wiki/Pairing_function
 * @see fromPairedNumber
 */
export function toPairedNumber(arg0: number, arg1: number): number {
    assertNaturalNumber(arg0);
    assertNaturalNumber(arg1);
    return 0.5 * (arg0 + arg1) * (arg0 + arg1 + 1) + arg1;
}
