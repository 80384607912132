import { SVGProps } from 'react';

export const BlockColumns50x50 = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="18" viewBox="0 0 35 18" width="35" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="14.7361" x="1.08606" y="1.36288" />
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="14.7361" x="19.2005" y="1.36288" />
    </svg>
);

BlockColumns50x50.displayName = 'BlockColumns50x50';
