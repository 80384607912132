/**
 * Provide shape of translation messages for type safety and autocompletion
 * ? Reference: https://next-intl-docs.vercel.app/docs/workflows/typescript
 */

import { Messages } from '@deltasierra/translations/core';
import { NestedKeyOf, NamespaceKeys, useTranslations as useTranslationsInitial } from 'next-intl';

declare global {
    interface IntlMessages extends Messages {}
}

export const useTranslations = useTranslationsInitial;

export type Translations<NestedKey extends NamespaceKeys<IntlMessages, NestedKeyOf<IntlMessages>> = never> = ReturnType<
    typeof useTranslations<NestedKey>
>;
