import { SVGProps } from 'react';

export const BlockColumns33x33x33 = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="18" viewBox="0 0 35 18" width="35" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="8.25813" x="1.08557" y="1.01497" />
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="8.25813" x="25.679" y="1.01497" />
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="8.25813" x="13.4171" y="1.01497" />
    </svg>
);

BlockColumns33x33x33.displayName = 'BlockColumns33x33x33';
