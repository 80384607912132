import { extendSx } from '@deltasierra/react/theme';
import { Box, Modal as MuiModal, ModalProps as MuiModalProps } from '@mui/material';

export type ModalFullPageProps = Pick<MuiModalProps, 'children' | 'onClose' | 'open' | 'sx'>;

export const ModalFullPage = ({ children, sx, ...props }: ModalFullPageProps): JSX.Element => (
    <MuiModal {...props}>
        <Box
            sx={extendSx(
                {
                    borderRadius: '8px',
                    display: 'flex',
                    height: '90vh',
                    left: '50%',
                    outline: 'none',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90vw',
                },
                sx,
            )}
        >
            {children}
        </Box>
    </MuiModal>
);

ModalFullPage.displayName = 'ModalFullPage';
