'use client';

import { createTheme, ThemeOptions } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { breakpoints } from './breakpoints';
import { components } from './components';
import { palette } from './palette';
import { typography } from './typography';
import { mixins } from './mixins';

const themeOptions: ThemeOptions = {
    breakpoints,
    components,
    mixins,
    palette,
    shape: {
        borderRadius: 8,
    },
    typography,
};

export const theme = createTheme(themeOptions);
