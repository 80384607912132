/* eslint-disable sort-keys */
import { ThemeOptions } from '@mui/material/styles';

export const breakpoints: ThemeOptions['breakpoints'] = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
    },
};
