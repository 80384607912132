import { TimePicker as MuiTimePicker, TimePickerProps as MuiTimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { DateTime } from 'luxon';

const today = DateTime.now();

export type TimePickerProps = Pick<
    MuiTimePickerProps<typeof today>,
    | 'disabled'
    | 'disableFuture'
    | 'disablePast'
    | 'format'
    | 'inputRef'
    | 'label'
    | 'maxTime'
    | 'minTime'
    | 'name'
    | 'onChange'
    | 'onError'
    | 'slotProps'
    | 'sx'
    | 'timeSteps'
    | 'value'
>;

export const TimePicker = (props: TimePickerProps): JSX.Element => <MuiTimePicker {...props} />;

TimePicker.displayName = 'TimePicker';
