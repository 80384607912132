import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';

const today = DateTime.now();

export type DatePickerProps = Pick<
    MuiDatePickerProps<typeof today>,
    | 'disabled'
    | 'disableFuture'
    | 'disablePast'
    | 'format'
    | 'inputRef'
    | 'label'
    | 'maxDate'
    | 'minDate'
    | 'name'
    | 'onChange'
    | 'onError'
    | 'openTo'
    | 'slotProps'
    | 'sx'
    | 'value'
>;

export const DatePicker = (props: DatePickerProps): JSX.Element => <MuiDatePicker format="dd/MM/yyyy" {...props} />;

DatePicker.displayName = 'DatePicker';
