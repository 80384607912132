import { toBase64 } from '@deltasierra/string-utilities';
import { theme } from '@deltasierra/react/theme';

export enum ColourIndex {
    Warning,
    Error,
    Success,
    Secondary,
    __LENGTH,
}

export const colorMap: Record<Exclude<ColourIndex, ColourIndex.__LENGTH>, string> = {
    [ColourIndex.Warning]: theme.palette.warning.main,
    [ColourIndex.Secondary]: theme.palette.secondary.main,
    [ColourIndex.Error]: theme.palette.error.main,
    [ColourIndex.Success]: theme.palette.success.main,
} as const;

type blankTemplateProps = { color: string };

const blankTemplate = ({ color }: blankTemplateProps): string =>
    `<svg fill="none" height="190" viewBox="0 0 192 190" width="192" xmlns="http://www.w3.org/2000/svg">
        <path d="M244.387 189.935H-69.6133V0.935257H244.387V189.935Z" fill="#E6E6E6" />
        <path d="M91.5447 134.588H-38.5493V56.2828H91.5447V134.588Z" fill="white" />
        <path d="M87.8776 62.7736H69.5679V58.9801H87.8776V62.7736Z" fill="#E6E6E6" />
        <path d="M42.9192 101.841H10.0767V68.8937H42.9192V101.841Z" fill="${color}" />
        <path d="M87.8774 109.457H-34.8975V106.545H87.8774V109.457Z" fill="#E6E6E6" />
        <path d="M87.8774 119.752H-34.8975V116.841H87.8774V119.752Z" fill="#E6E6E6" />
        <path d="M87.8774 125.107H-34.8975V122.195H87.8774V125.107Z" fill="#E6E6E6" />
        <path d="M87.8774 130.383H-34.8975V127.471H87.8774V130.383Z" fill="#E6E6E6" />
        <path d="M87.8774 114.543H-34.8975V111.631H87.8774V114.543Z" fill="#E6E6E6" />
        <path d="M87.8774 109.457H-34.8975V106.545H87.8774V109.457Z" fill="#E6E6E6" />
        <path d="M155.091 134.18H101.068V56.6904H155.091V134.18Z" fill="white" />
        <path d="M140.856 92.4906H115.304V66.8582H140.856V92.4906Z" fill="${color}" />
        <path d="M106.829 62.4161H103.483V59.0591H106.829V62.4161Z" fill="#E6E6E6" />
        <path d="M152.531 62.4161H138.485V59.0591H152.531V62.4161Z" fill="#E6E6E6" />
        <path d="M152.531 97.0978H103.483V94.991H152.531V97.0978Z" fill="#E6E6E6" />
        <path d="M152.531 100.317H103.483V98.2103H152.531V100.317Z" fill="#E6E6E6" />
        <path d="M152.531 103.906H103.483V101.799H152.531V103.906Z" fill="#E6E6E6" />
        <path d="M152.531 107.27H103.483V105.163H152.531V107.27Z" fill="#E6E6E6" />
        <path d="M152.531 112.302H129.918V110.195H152.531V112.302Z" fill="#E6E6E6" />
        <path d="M152.531 115.521H129.918V113.414H152.531V115.521Z" fill="#E6E6E6" />
        <path d="M152.531 119.11H129.918V117.003H152.531V119.11Z" fill="#E6E6E6" />
        <path d="M152.531 122.474H129.918V120.367H152.531V122.474Z" fill="#E6E6E6" />
        <path d="M152.531 126.336H129.918V124.229H152.531V126.336Z" fill="#E6E6E6" />
        <path d="M152.531 129.7H129.918V127.593H152.531V129.7Z" fill="#E6E6E6" />
        <path d="M127.147 129.876H103.463V110.343H127.147V129.876Z" fill="#E6E6E6" />
        <path d="M213.323 133.368H166.399V56.2828H213.323V133.368Z" fill="white" />
        <path d="M211.197 108.328H191.678V106.222H211.197V108.328Z" fill="#E6E6E6" />
        <path d="M211.197 111.548H191.678V109.441H211.197V111.548Z" fill="#E6E6E6" />
        <path d="M211.197 115.137H191.678V113.03H211.197V115.137Z" fill="#E6E6E6" />
        <path d="M211.197 118.501H191.678V116.394H211.197V118.501Z" fill="#E6E6E6" />
        <path d="M211.197 122.363H191.678V120.256H211.197V122.363Z" fill="#E6E6E6" />
        <path d="M211.197 125.727H191.678V123.62H211.197V125.727Z" fill="#E6E6E6" />
        <path d="M189.285 125.903H168.842V106.369H189.285V125.903Z" fill="#E6E6E6" />
        <path d="M171.809 61.6209H168.506V58.4494H171.809V61.6209Z" fill="#E6E6E6" />
        <path d="M200.36 85.2286H179.679V64.4827H200.36V85.2286Z" fill="${color}" />
        <path d="M211.197 90.462H168.842V88.3554H211.197V90.462Z" fill="#E6E6E6" />
        <path d="M211.197 93.6808H168.842V91.5742H211.197V93.6808Z" fill="#E6E6E6" />
        <path d="M211.197 97.2701H168.842V95.1635H211.197V97.2701Z" fill="#E6E6E6" />
        <path d="M211.197 100.634H168.842V98.5271H211.197V100.634Z" fill="#E6E6E6" />
        <path d="M211.197 104.263H168.842V102.156H211.197V104.263Z" fill="#E6E6E6" />
        <path d="M211.197 129.627H168.842V127.52H211.197V129.627Z" fill="#E6E6E6" />
    </svg>`;

blankTemplate.displayName = 'blankTemplate';

export const blankTemplateImageString = (color: string): `data:image/${string}` =>
    `data:image/svg+xml;base64,${toBase64(blankTemplate({ color }))}`;
