/* eslint-disable max-len */
import { SVGProps } from 'react';

export const Doughnut = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="4" viewBox="0 0 4 4" width="4" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            clipRule="evenodd"
            d="M2.00015 1.24481C1.92968 1.24481 1.87256 1.30194 1.87256 1.37241C1.87256 1.44287 1.92968 1.5 2.00015 1.5C2.07062 1.5 2.12774 1.44287 2.12774 1.37241C2.12774 1.30194 2.07062 1.24481 2.00015 1.24481ZM0.872559 1.37241C0.872559 0.749653 1.3774 0.244812 2.00015 0.244812C2.6229 0.244812 3.12774 0.749653 3.12774 1.37241C3.12774 1.99516 2.6229 2.5 2.00015 2.5C1.3774 2.5 0.872559 1.99516 0.872559 1.37241Z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

Doughnut.displayName = 'Doughnut';
