import { useEffect, useRef, useState } from 'react';

type ComponentRefFunc = (node: Element | null) => void;

export type WatchElementRefType = (key: unknown) => ComponentRefFunc;

export function useInfiniteScroll(onElementVisible: () => void): {
    watchElementRef: WatchElementRefType;
} {
    // We have to return the same "ref" function for every item with the same key
    const watchElementMapRef = useRef<Map<unknown, ComponentRefFunc>>();
    const getWatchElementMap = () => {
        if (!watchElementMapRef.current) {
            watchElementMapRef.current = new Map();
        }
        return watchElementMapRef.current;
    };

    // And we have to store a map of items that can be updated with the elements to watch
    const [map, setMap] = useState<Map<unknown, Element>>(() => new Map());

    // This use effect updates the observer to watch the elements in our map
    useEffect(() => {
        const values = [...map.values()];
        const observer = new IntersectionObserver(entries => {
            const found = entries.filter(entry => entry.isIntersecting);
            if (found.length > 0) {
                onElementVisible();
            }
        });

        for (const ref of values) {
            observer.observe(ref);
        }

        return () => {
            for (const ref of values) {
                observer.unobserve(ref);
            }
        };
    }, [onElementVisible, map]);

    // This helper function returns the current
    const watchElementRef: WatchElementRefType = key => {
        const watchElementMap = getWatchElementMap();

        if (!watchElementMap.has(key)) {
            watchElementMap.set(key, node => {
                setMap(oldMap => {
                    const newMap = new Map(oldMap.entries());
                    if (node) {
                        newMap.set(key, node);
                    } else {
                        newMap.delete(key);
                    }
                    return newMap;
                });
            });
        }

        return watchElementMap.get(key)!;
    };

    return {
        watchElementRef,
    };
}
