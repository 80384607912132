import { sanitizeFileNameForUpload } from './sanitizeFileNameForUpload';

export const uploadToS3 = async (signedUrl: string, file: File): Promise<string> =>
    new Promise<string>((resolve, reject) => {
        const filename = sanitizeFileNameForUpload(file.name);
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrl);
        xhr.setRequestHeader('Content-Disposition', `filename="${filename}"`);
        // DS-2224, DS-2469: IE11 needs this header for uploads, but giving an empty value breaks fonts in Chrome.
        if (file.type) {
            xhr.setRequestHeader('Content-Type', file.type);
        }
        const handleResponse = () => {
            if (xhr.status === 200) {
                resolve(xhr.responseText);
            } else {
                const response = xhr.responseText || xhr.status || 'Upload failed';
                reject(response);
            }
        };
        xhr.onload = handleResponse;
        xhr.onerror = handleResponse;

        xhr.send(file);
    });
