import { useEffect, useState } from 'react';

/**
 * Returns the pressed state of the provided key
 *
 * @see https://usehooks.com/useKeyPress/
 * @example
 * const keyPressed = useIsKeyPressed('h');
 * return <>{keyPressed && <div>{'Pressed the H Key!'}</div>}</>;
 * @param targetKey - The key to watch pressed status.
 * See https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 * @param timeout - Optional flag that takes in the time you want till you set the keypress back to false
 * This is incase someone is holding it down
 * @param shouldPreventDefault - Optional flag to prevent the keyboard events from continuing past the listener.
 * Careful setting this to true, as long as the parent component is mounted,
 * the key won't do anything outside setting the return value
 * @returns - A boolean representing whether the provided key is pressed or not
 */
export function useIsKeyPressed(targetKey: string, timeout: number | undefined, shouldPreventDefault = false): boolean {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    // Add event listeners
    useEffect(() => {
        // If pressed key is our target key then set to true
        function downHandler(ev: KeyboardEvent) {
            if (ev.key === targetKey && !ev.repeat) {
                setKeyPressed(true);
                if (shouldPreventDefault) {
                    ev.preventDefault();
                }
                if (timeout) {
                    setTimeout(() => {
                        setKeyPressed(false);
                    }, timeout);
                }
            }
        }

        // If released key is our target key then set to false
        const upHandler = (ev: KeyboardEvent) => {
            if (ev.key === targetKey) {
                setKeyPressed(false);
                if (shouldPreventDefault) {
                    ev.preventDefault();
                }
            }
        };
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [targetKey, shouldPreventDefault, timeout, keyPressed]);

    return keyPressed;
}
