import { Dimensions, useFluidElementWidth, useFluidGrid } from '@deltasierra/react/hooks/common';
import { Box } from '@mui/material';
import { Key, ReactElement, ReactNode, useMemo } from 'react';

export type FluidGridAspectRatio<ItemKey> = [ItemKey, Dimensions];

export type FluidGridItemProps<T, ItemKey = string> = {
    data: T;
    dimensions: Dimensions;
    key: ItemKey;
};

export type FluidGridProps<T, ItemKey extends Key> = {
    aspectRatios: Array<FluidGridAspectRatio<ItemKey>>;
    children: (props: FluidGridItemProps<T, ItemKey>) => ReactNode;
    data: T;
    dataCy?: string;
    itemMinWidth: number;
    rowMaxHeight: number;
    rowMinHeight: number;
    /**
     * @default 10
     */
    skeleton?: ReactNode | false;
    spacing?: number;
};

export function FluidGrid<T, ItemKey extends Key>({
    aspectRatios: aspectRatiosWithKeys,
    children,
    data,
    dataCy,
    itemMinWidth,
    rowMaxHeight,
    rowMinHeight,
    skeleton,
    spacing = 10,
}: FluidGridProps<T, ItemKey>): ReactElement<FluidGridProps<T, ItemKey>> {
    const { ref, rowWidth } = useFluidElementWidth<HTMLDivElement>();
    const aspectRatios = useMemo(
        () => aspectRatiosWithKeys.map(([_key, aspectRatio]) => aspectRatio),
        [aspectRatiosWithKeys],
    );
    const itemSizes = useFluidGrid({
        aspectRatios,
        itemMinWidth,
        rowMaxHeight,
        rowMinHeight,
        rowWidth,
        spacing,
    });

    return (
        <Box
            data-cy={dataCy}
            ref={ref}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                minWidth: '100%',
                rowGap: `${spacing}px`,
            }}
        >
            {itemSizes.map((dimensions, index) => (
                <Box key={aspectRatiosWithKeys[index][0]} sx={{ width: dimensions.width }}>
                    {children({
                        data,
                        dimensions,
                        key: aspectRatiosWithKeys[index][0],
                    })}
                </Box>
            ))}
            {skeleton}
        </Box>
    );
}
FluidGrid.displayName = 'FluidGrid';
