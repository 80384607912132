import { isNullOrUndefined } from '../is-null-or-undefined';

/**
 * A type narrower to invert the `isNullOrUndefined` check.
 * Returns true if the value is not `null` or `undefined`
 *
 * @param val - The val
 * @returns Boolean
 */
export function isNotNullOrUndefined<T>(val: T | null | undefined): val is T {
    return !isNullOrUndefined(val);
}
