/* eslint-disable max-len */
import { SVGProps } from 'react';

export const Social = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="25" viewBox="0 0 28 27" width="25" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3.42212 8.775C3.42212 6.88482 3.42212 5.93973 3.78997 5.21778C4.11354 4.58274 4.62985 4.06643 5.2649 3.74285C5.98685 3.375 6.93194 3.375 8.82212 3.375H18.2721C20.1623 3.375 21.1074 3.375 21.8293 3.74285C22.4644 4.06643 22.9807 4.58274 23.3043 5.21778C23.6721 5.93973 23.6721 6.88482 23.6721 8.775V14.85C23.6721 16.7402 23.6721 17.6853 23.3043 18.4072C22.9807 19.0423 22.4644 19.5586 21.8293 19.8821C21.1074 20.25 20.1623 20.25 18.2721 20.25H15.4413C14.7393 20.25 14.3882 20.25 14.0525 20.3189C13.7546 20.38 13.4663 20.4812 13.1955 20.6195C12.8903 20.7754 12.6162 20.9947 12.068 21.4333L9.38435 23.5802C8.91625 23.9547 8.6822 24.1419 8.48523 24.1421C8.31392 24.1423 8.15187 24.0644 8.045 23.9306C7.92212 23.7766 7.92212 23.4769 7.92212 22.8774V20.25C6.8759 20.25 6.35279 20.25 5.9236 20.135C4.75892 19.8229 3.8492 18.9132 3.53712 17.7485C3.42212 17.3193 3.42212 16.7962 3.42212 15.75V8.775Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
        <path
            clipRule="evenodd"
            d="M13.5437 8.66166C12.5315 7.49295 10.8436 7.17857 9.57543 8.24876C8.30725 9.31894 8.12871 11.1082 9.12462 12.374C9.74162 13.1581 11.2902 14.5903 12.3793 15.5666C12.7794 15.9252 12.9794 16.1045 13.2194 16.1767C13.4254 16.2387 13.6619 16.2387 13.8679 16.1767C14.1079 16.1045 14.3079 15.9252 14.708 15.5666C15.7971 14.5903 17.3457 13.1581 17.9627 12.374C18.9586 11.1082 18.8018 9.30768 17.5119 8.24876C16.2219 7.18983 14.5558 7.49295 13.5437 8.66166Z"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
    </svg>
);

Social.displayName = 'Social';
