import { Buffer } from 'buffer';
import { Brand, Branded, createBranded } from './branded-types/brand';

export type ObjectBaseType<Keys> = {
    [key in keyof Keys]: number | string;
};

export type IdFromData<
    Branding extends string,
    ObjectType extends ObjectBaseType<Keys>,
    Keys = ObjectType
> = Brand<string, Branding>;

export function createIdFromDataSerializers<
    IdType extends IdFromData<any, ObjectType>,
    ObjectType extends ObjectBaseType<Keys>,
    Keys = ObjectType
>(): [(obj: ObjectType) => IdType, (id: IdType) => ObjectType, Branded<IdType>] {
    function serializer(obj: ObjectType): IdType {
        const json = JSON.stringify(obj);
        const encodedBase64String = Buffer.from(json).toString('base64');
        return encodedBase64String as IdType;
    }

    function deserializer(id: IdType): ObjectType {
        const decodedBase64String = Buffer.from(id, 'base64').toString();
        const obj = JSON.parse(decodedBase64String);
        return obj as ObjectType;
    }

    return [serializer, deserializer, createBranded<IdType>()];
}
