/* eslint-disable max-len */
import { SVGProps } from 'react';

export const NumberedList = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="27" viewBox="0 0 27 27" width="27" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M22.934 5.72266L10.9327 5.72266M22.934 20.997L10.9327 20.997M22.934 13.3598L10.9327 13.3598"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
        <path
            d="M6.54336 7.89022V2.80182C6.53656 2.46382 6.01616 2.32192 5.75956 2.46602L4.70336 3.10602C4.23746 3.33982 4.54256 4.27542 5.06336 3.96202L5.29526 3.81792V7.89022C5.33816 8.41362 6.49996 8.41332 6.54336 7.89022Z"
            fill="currentColor"
        />
        <path
            d="M3.83536 15.8867C3.82216 16.1304 4.18456 16.2725 4.37926 16.2704H7.22746C7.72166 16.2216 7.73166 15.2249 7.22746 15.1826H5.13126C5.15416 14.579 5.94146 14.352 6.36706 13.9745C6.71198 13.7831 7.00491 13.5103 7.22037 13.1799C7.43584 12.8495 7.56731 12.4714 7.60336 12.0786C7.59373 11.8461 7.53736 11.6179 7.43763 11.4076C7.33789 11.1973 7.19683 11.0093 7.02286 10.8547C6.84888 10.7002 6.64555 10.5822 6.425 10.5079C6.20446 10.4336 5.9712 10.4045 5.73916 10.4223C5.51905 10.4016 5.29702 10.4252 5.08616 10.4916C4.87531 10.5581 4.67988 10.6661 4.51141 10.8092C4.34293 10.9524 4.20481 11.1278 4.1052 11.3252C4.00558 11.5225 3.94647 11.7379 3.93136 11.9584C3.80446 12.7761 5.06166 12.8041 5.14766 12.1503C5.21426 11.0069 6.93556 11.6492 6.17156 12.6064C5.59326 13.3296 4.53726 13.7398 4.01536 14.5224C3.8986 14.6963 3.83596 14.9009 3.83536 15.1103V15.8867Z"
            fill="currentColor"
        />
        <path
            d="M6.82356 21.1586C7.05144 21.2393 7.25323 21.3802 7.40756 21.5663C7.57124 21.8706 7.65354 22.2121 7.6465 22.5575C7.63945 22.903 7.5433 23.2408 7.36736 23.5382C7.15981 23.7983 6.89141 24.0034 6.58586 24.1352C6.2803 24.267 5.94699 24.3215 5.61536 24.294C5.39027 24.3249 5.16126 24.3099 4.94214 24.2499C4.72301 24.1899 4.5183 24.0861 4.34036 23.9449C4.16241 23.8036 4.01492 23.6278 3.90677 23.428C3.79861 23.2282 3.73204 23.0085 3.71106 22.7823C3.71706 22.3727 3.90686 22.3386 4.30336 22.3263L4.32122 22.327C4.72827 22.3425 4.92719 22.35 4.94336 22.7423C4.93156 23.3287 5.95916 23.2923 6.22756 23.0423C6.44776 22.8314 6.49016 21.9704 6.17136 21.8143C6.00173 21.729 5.81334 21.6877 5.62356 21.6943C5.13486 21.703 5.13246 20.7222 5.62356 20.7343C5.72328 20.7346 5.82266 20.7226 5.91946 20.6986C6.19176 20.6085 6.24256 20.3763 6.24756 20.0463C6.25746 19.6081 6.02446 19.4806 5.65526 19.4706C5.37076 19.4622 5.04176 19.5369 5.06346 19.8783C5.04946 20.2218 4.87376 20.3356 4.47946 20.3343C4.05886 20.3481 3.80156 20.2678 3.83156 19.8305C3.84947 19.6136 3.91195 19.4027 4.0151 19.211C4.11825 19.0194 4.25984 18.8511 4.43102 18.7166C4.6022 18.5822 4.79928 18.4846 5.00994 18.4298C5.22059 18.375 5.44028 18.3643 5.65526 18.3983C5.94134 18.3689 6.23035 18.4068 6.49918 18.5089C6.76802 18.6111 7.00923 18.7747 7.20356 18.9867C7.40906 19.3347 7.48369 19.7446 7.41404 20.1427C7.34438 20.5408 7.13501 20.9011 6.82356 21.1586Z"
            fill="currentColor"
        />
    </svg>
);

NumberedList.displayName = 'NumberedList';
