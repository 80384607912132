import { ThemeOptions } from '@mui/material/styles';

export const palette: ThemeOptions['palette'] = {
    error: {
        contrastText: '#ffffff',
        dark: '#b7263e',
        light: '#ffe8db',
        main: '#c83937',
    },
    info: {
        contrastText: '#ffffff',
        dark: '#5223d7',
        light: '#f0eaff',
        main: '#6b30fa',
    },
    mode: 'light',
    primary: {
        contrastText: '#ffffff',
        dark: '#5223d7',
        light: '#f0eaff',
        main: '#6b30fa',
    },
    secondary: {
        contrastText: '#ffffff',
        dark: '#e46abf',
        light: '#ffe3ec',
        main: '#ff78d6',
    },
    success: {
        contrastText: '#ffffff',
        dark: '#065557',
        light: '#c9f7e8',
        main: '#08696b',
    },
    text: {
        disabled: '#9E9E9E',
        primary: '#000000',
        secondary: '#000000',
    },
    warning: {
        contrastText: '#ffffff',
        dark: '#d2b23d',
        light: '#fef9da',
        main: '#f5cf47',
    },
};
