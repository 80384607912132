/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from '@mui/material';
import { extendSx } from '@deltasierra/react/theme';
import { forwardRef, ForwardedRef } from 'react';

export type SkeletonProps = Pick<
    MuiSkeletonProps,
    'animation' | 'children' | 'component' | 'height' | 'ref' | 'sx' | 'variant' | 'width'
>;

export const Skeleton = forwardRef(
    ({ sx, ...props }: SkeletonProps, ref?: ForwardedRef<any>): JSX.Element => (
        <MuiSkeleton {...props} ref={ref} sx={extendSx({ animationDuration: '3s' }, sx)} />
    ),
);

Skeleton.displayName = 'Skeleton';
