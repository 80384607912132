export function titleCase(text: string | null | undefined, options: { removeSpaces?: boolean } = {}): string | null {
    if (!text) {
        return null;
    }

    let result = text.replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());

    if (options.removeSpaces ?? true) {
        result = result.replace(/\s/g, '');
    }

    return result;
}

export function titleCaseFirst(text: string): string {
    return text.substr(0, 1).toUpperCase() + text.substr(1);
}
