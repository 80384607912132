import { Button, ButtonGroup, MenuItem, MenuList, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { useState, useCallback, Fragment, MouseEventHandler } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

export type SplitButtonDropdownProps = {
    ariaLabel?: string;
    buttonText: string;
    buttonWidth?: string;
    disabled?: boolean;
    dropdownWidth?: string;
    id: string;
    isDropdownOpen: boolean;
    onClickButton: MouseEventHandler<HTMLButtonElement>;
    onClickDropdown: MouseEventHandler<HTMLButtonElement>;
    onCloseDropdown: () => void;
    options: Array<{ value: string; onClick: MouseEventHandler<HTMLLIElement> }>;
    sx?: SxProps<Theme>;
};

// ? Reference https://mui.com/material-ui/react-button-group/#split-button

export const SplitButtonDropdown = ({
    ariaLabel,
    buttonText,
    buttonWidth = '8rem',
    disabled,
    dropdownWidth = '10.5rem',
    id,
    isDropdownOpen,
    onClickButton,
    onClickDropdown,
    onCloseDropdown,
    options,
    sx,
}: SplitButtonDropdownProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | undefined>(undefined);

    const ref = useCallback(node => {
        setAnchorEl(node);
    }, []);

    const listId = `split-button-menu-${id}`;

    return (
        <Fragment>
            <ButtonGroup aria-label={ariaLabel} disabled={disabled} ref={ref} sx={sx} variant="contained">
                <Button sx={{ width: buttonWidth }} onClick={onClickButton}>
                    {buttonText}
                </Button>
                <Button
                    aria-controls={isDropdownOpen ? listId : undefined}
                    aria-expanded={isDropdownOpen ? 'true' : undefined}
                    aria-haspopup="menu"
                    size="small"
                    onClick={onClickDropdown}
                >
                    <span
                        style={{
                            fontSize: '0.4em',
                            marginBottom: '0.2em',
                            transform: 'rotate(45deg)',
                        }}
                    >
                        {'◢'}
                    </span>
                </Button>
            </ButtonGroup>
            {anchorEl && (
                <Popper
                    anchorEl={anchorEl}
                    open={isDropdownOpen}
                    placement="bottom-end"
                    sx={{ zIndex: 'drawer' }}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={onCloseDropdown}>
                                    <MenuList autoFocusItem id={listId}>
                                        {options.map(option => (
                                            <MenuItem
                                                key={option.value}
                                                sx={{ width: dropdownWidth }}
                                                onClick={event => {
                                                    option.onClick(event);
                                                    onCloseDropdown();
                                                }}
                                            >
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </Fragment>
    );
};

SplitButtonDropdown.displayName = 'SplitButtonDropdown';
