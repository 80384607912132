/**
 * Asserts that the given argument is a natural number (positive integer and not zero).
 *
 * @example
 * assertNaturalNumber(1) // No error
 * assertNaturalNumber(0.5) // Error thrown
 * @param value - The value to check
 */
export function assertNaturalNumber(value: number): asserts value is number {
    if (value <= 0 || !Number.isSafeInteger(value)) {
        throw new Error(`Expected positive integer (natural number) but received "${value}"`);
    }
}
