import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

export type ButtonProps = Pick<
    MuiButtonProps,
    | 'aria-label'
    | 'children'
    | 'className'
    | 'color'
    | 'disabled'
    | 'disableTouchRipple'
    | 'endIcon'
    | 'href'
    | 'onClick'
    | 'size'
    | 'startIcon'
    | 'sx'
    | 'type'
    | 'variant'
>;

export const Button = (props: ButtonProps): JSX.Element => <MuiButton {...props} />;

Button.displayName = 'Button';
