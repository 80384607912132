import { Box, ClickAwayListener, Grow, Paper, Popper, ToggleButton, ToggleButtonProps } from '@mui/material';
import { ArrowDropDownIcon } from '@deltasierra/react/icons';
import { ReactElement, useCallback, useState } from 'react';
import { theme } from '@deltasierra/react/theme';

export type DropdownUncontrolledOpenProps = {
    ariaLabel: string;
    buttonChildren: ReactElement;
    buttonSx?: ToggleButtonProps['sx'];
    children: (onCloseDropdown: () => void) => ReactElement;
    color?: ToggleButtonProps['color'];
    disabled?: boolean;
    hideArrow?: boolean;
    onChangeOpen?: () => void;
    onClickAway?: () => void;
    selectedOverride?: boolean;
};

export const DropdownUncontrolledOpen = ({
    ariaLabel,
    buttonChildren,
    buttonSx,
    children,
    color = 'primary',
    disabled,
    hideArrow,
    onChangeOpen,
    onClickAway,
    selectedOverride,
}: DropdownUncontrolledOpenProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | undefined>(undefined);
    const [open, setOpen] = useState(false);

    const ref = useCallback(node => {
        setAnchorEl(node);
    }, []);

    const onCloseDropdown = () => {
        setOpen(false);
    };

    return (
        <div ref={ref} style={{ width: '100%' }}>
            <ToggleButton
                aria-label={ariaLabel}
                color={color}
                disabled={disabled}
                selected={open || selectedOverride}
                sx={{
                    '&.MuiToggleButtonGroup-grouped': {
                        border: 0,
                        width: '100%',
                    },
                    paddingX: 3,
                    ...buttonSx,
                }}
                value={ariaLabel}
                onChange={() => {
                    setOpen(prev => !prev);
                    onChangeOpen?.();
                }}
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    {buttonChildren}
                    {!hideArrow && (
                        <ArrowDropDownIcon
                            style={{
                                color: theme.palette.grey[700],
                                margin: '0 -6',
                                scale: 0.6,
                            }}
                        />
                    )}
                </Box>
            </ToggleButton>
            <Popper
                anchorEl={anchorEl}
                disablePortal
                open={open}
                placement="bottom-end"
                sx={{
                    zIndex: 'fab',
                }}
                transition
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center top',
                        }}
                    >
                        <Paper
                            sx={{
                                boxShadow: theme.mixins.boxShadow.overlay,
                            }}
                        >
                            <ClickAwayListener
                                onClickAway={() => {
                                    onCloseDropdown();
                                    onClickAway?.();
                                }}
                            >
                                {children(onCloseDropdown)}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

DropdownUncontrolledOpen.displayName = 'DropdownUncontrolledOpen';
