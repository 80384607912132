/* eslint-disable max-len */
import { SVGProps } from 'react';

export const ButtonClick = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M18.5054 7.24407V5.65427C18.5054 4.08302 18.5054 3.29739 18.1997 2.69726C17.9307 2.16936 17.5015 1.74017 16.9736 1.47119C16.3735 1.16541 15.5878 1.16541 14.0166 1.16541H6.16107C4.58982 1.16541 3.8042 1.16541 3.20406 1.47119C2.67617 1.74017 2.24697 2.16936 1.978 2.69726C1.67221 3.29739 1.67221 4.08302 1.67221 5.65427V13.5098C1.67221 15.081 1.67221 15.8666 1.978 16.4668C2.24697 16.9947 2.67617 17.4239 3.20406 17.6928C3.8042 17.9986 4.58982 17.9986 6.16107 17.9986H7.75088M15.1259 14.9231L13.5377 17.8726C13.2781 18.3548 13.1483 18.5958 12.9901 18.6587C12.8527 18.7134 12.6976 18.6998 12.5718 18.6222C12.4269 18.5328 12.3409 18.2729 12.1687 17.7531L9.62161 10.0602C9.47089 9.60498 9.39553 9.37737 9.44971 9.2258C9.49687 9.09389 9.60069 8.99007 9.73261 8.94291C9.88418 8.88872 10.1118 8.96408 10.567 9.1148L18.2599 11.662C18.7797 11.8341 19.0396 11.9201 19.129 12.065C19.2066 12.1908 19.2201 12.3459 19.1655 12.4833C19.1026 12.6415 18.8615 12.7713 18.3794 13.0309L15.4299 14.6191C15.3565 14.6587 15.3197 14.6784 15.2876 14.7038C15.259 14.7264 15.2332 14.7522 15.2107 14.7808C15.1852 14.8129 15.1655 14.8497 15.1259 14.9231Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
    </svg>
);

ButtonClick.displayName = 'ButtonClick';
