import { Brand } from '../brand';
import { fromPairedNumber } from './from-paired-number';
import { toPairedNumber } from './to-paired-number';

export type Pair<A extends number, B extends number, Branding extends string = 'Pair'> = Brand<number, Branding> & {
    __pairA: A;
    __pairB: B;
};

type AnyPair = Pair<number, number, string>;

/**
 * A typed pairing function takes two positive integers and outputs a single integer that's unique to the inputs.
 * There will always be a pairing and it will always be unique to the two inputs. Order of the inputs is important.
 *
 * @example
 * toTypedPair(47, 32) // Output: 3192
 * @param arg0 - The first integer of the pairing
 * @param arg1 - The second integer of the pairing
 * @returns A unique integer derived from the two inputs
 * @see https://en.wikipedia.org/wiki/Pairing_function
 * @see fromTypedPair
 */
function toTypedPair<P extends Pair<number, number, string>>(arg0: FirstPairType<P>, arg1: SecondPairType<P>): P {
    return toPairedNumber(arg0, arg1) as P;
}

/**
 * This is the reverse of the typed pairing function - it takes a single positive integer and outputs the two pairs that
 * were combined to create it. There always exists a unique pairing for every positive integer. Order of the outputs are
 * important.
 *
 * @example
 * fromTypedPair(1432) // Output: [52, 1]
 * @param value - The integer to find the pair for
 * @returns The two integers that correspond to the given input
 * @see https://en.wikipedia.org/wiki/Pairing_function
 * @see toTypedPair
 */

function fromTypedPair<P extends Pair<number, number, string>>(pair: P): [FirstPairType<P>, SecondPairType<P>] {
    return fromPairedNumber(pair) as [FirstPairType<P>, SecondPairType<P>];
}

type FirstPairType<P extends Pair<number, number, string>> = P extends Pair<infer A, number, string> ? A : never;
type SecondPairType<P extends Pair<number, number, string>> = P extends Pair<number, infer B, string> ? B : never;

/**
 * BrandedPair
 */
export type BrandedPair<P extends Pair<number, number, string>> = {
    create: (val1: FirstPairType<P>, val2: SecondPairType<P>) => P;
    explode: (pair: P) => [FirstPairType<P>, SecondPairType<P>];
    from: (pairedNumber: number) => P;
};

/**
 * CreateBrandedPair
 *
 * @param from - An optional branding function
 * @returns - The branded pair
 */
export function createBrandedPair<P extends AnyPair>(from?: (val: number) => P): Readonly<BrandedPair<P>> {
    return Object.freeze({
        create: toTypedPair,
        explode: fromTypedPair,
        from: from ?? ((val: number) => val as P),
    });
}
