import { ThemeOptions, SimplePaletteColorOptions } from '@mui/material/styles';
import { palette } from './palette';

export const mixins: ThemeOptions['mixins'] = {
    borders: {
        dashed: '1px dashed var(--divider, rgb(0, 0, 0, 0.23))',
        dashedHighlight: `1px dashed var(--divider, ${(palette?.primary as SimplePaletteColorOptions).main})`,
        error: `1px solid var(--error-main, ${(palette?.error as SimplePaletteColorOptions)?.main})`,
        solidInput: '1px solid var(--divider, rgb(0, 0, 0, 0.23))',
    },
    boxShadow: {
        overlay: '0px 0px 16px 0px rgba(37, 37, 37, 0.25);',
    },
};

declare module '@mui/material/styles/createMixins' {
    // Allow for custom mixins to be added
    interface Mixins {
        borders: {
            dashed: string;
            dashedHighlight: string;
            error: string;
            solidInput: string;
        };
        boxShadow: {
            overlay: string;
        };
    }
}
