import { SVGProps } from 'react';

export const BlockColumns25x25x25x25 = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="18" viewBox="0 0 35 18" width="35" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="6.18721" x="1.08588" y="1.26374" />
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="6.18721" x="9.97382" y="1.26374" />
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="6.18721" x="18.8618" y="1.26374" />
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="6.18721" x="27.7496" y="1.26374" />
    </svg>
);

BlockColumns25x25x25x25.displayName = 'BlockColumns25x25x25x25';
