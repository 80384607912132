import { BuildableTemplateType } from '@deltasierra/frontend/graphql';
import { ServiceName } from '@deltasierra/integrations/integration-types';
import { PlatformIconPaths } from '../constants';

/**
 *
 * @param type - platformName (as ServiceName) or templateType
 * @returns iconSrc (image path)
 */
export function convertTemplateTypeOrPlatformNameToIcon(type: BuildableTemplateType | ServiceName): string {
    let iconSrc;

    if (type === BuildableTemplateType.Email) {
        iconSrc = PlatformIconPaths.emailCampaigns;
    } else if (type === BuildableTemplateType.Image) {
        iconSrc = PlatformIconPaths.all;
    } else if (type === BuildableTemplateType.Print) {
        iconSrc = PlatformIconPaths.print;
    } else if (type === BuildableTemplateType.Video) {
        iconSrc = PlatformIconPaths.video;
    } else if (type in PlatformIconPaths) {
        iconSrc = PlatformIconPaths[type as keyof typeof PlatformIconPaths];
    } else {
        iconSrc = PlatformIconPaths.all;
    }

    return iconSrc;
}
