import { ReadonlyURLSearchParams } from 'next/navigation';

export function getUrlWithSearchParams(
    searchParams: ReadonlyURLSearchParams,
    currentRoutePathName: string,
    additionalQueryParams: URLSearchParams,
): string {
    const updatedSearchParams = new URLSearchParams(searchParams);
    additionalQueryParams.forEach((value, key) => {
        updatedSearchParams.set(key, value);
    });
    return `${currentRoutePathName}?${updatedSearchParams.toString()}`;
}
