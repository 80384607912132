import { gql } from '@apollo/client';

export const INITIALIZE_UPLOAD_FOR_FILE = gql`
    mutation InitializeUploadForFile($input: InitializeUploadInput!) {
        uploadInitialize(input: $input) {
            upload {
                id
                filename
                size
                url
            }
            signedUrl
        }
    }
`;
