import { ArrowDropDownIcon } from '@deltasierra/react/icons';
import { Box, Checkbox, Collapse, Stack } from '@mui/material';
import { ReactNode } from 'react';

export type ToggleCollapseProps = {
    children?: ReactNode;
    handleSetOpen: () => void;
    isOpen: boolean;
    title: ReactNode;
};

export const ToggleCollapse = ({ children, handleSetOpen, isOpen, title }: ToggleCollapseProps): JSX.Element => (
    <Stack>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
            {title}
            <Checkbox
                checked={isOpen}
                checkedIcon={<ArrowDropDownIcon />}
                icon={<ArrowDropDownIcon style={{ rotate: '270deg' }} />}
                inputProps={{
                    'aria-label': 'Toggle collapse checkbox',
                }}
                onChange={handleSetOpen}
            />
        </Box>
        <Collapse in={isOpen}>{children}</Collapse>
    </Stack>
);

ToggleCollapse.displayName = 'ToggleCollapse';
