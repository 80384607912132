import { useMutation } from '@apollo/client';
import {
    INITIALIZE_UPLOAD_FOR_FILE,
    InitializeUploadForFileMutation,
    InitializeUploadForFileMutationVariables,
    UPLOAD_FAILURE_FOR_FILE,
    UPLOAD_SUCCESS_FOR_FILE,
    UploadFailureForFileMutation,
    UploadFailureForFileMutationVariables,
    UploadSuccessForFileMutation,
    UploadSuccessForFileMutationVariables,
} from '@deltasierra/frontend/graphql';
import { uploadToS3 } from '@deltasierra/utilities/upload';
import * as Sentry from '@sentry/nextjs';
import { useCallback, useState } from 'react';

export type UseUploadFileUploadResult =
    | (InitializeUploadForFileMutation['uploadInitialize']['upload'] & { fallbackFileUrl: string })
    | null;

export function useUploadFile(): {
    loading: boolean;
    triggerUpload: (file: File, category: string) => Promise<UseUploadFileUploadResult>;
} {
    const [initializeUpload] = useMutation<InitializeUploadForFileMutation, InitializeUploadForFileMutationVariables>(
        INITIALIZE_UPLOAD_FOR_FILE,
    );
    const [uploadSuccess] = useMutation<UploadSuccessForFileMutation, UploadSuccessForFileMutationVariables>(
        UPLOAD_SUCCESS_FOR_FILE,
    );
    const [uploadFailure] = useMutation<UploadFailureForFileMutation, UploadFailureForFileMutationVariables>(
        UPLOAD_FAILURE_FOR_FILE,
    );

    const [loading, setLoading] = useState(false);

    const triggerUpload = useCallback(
        async (file: File, category: string): Promise<UseUploadFileUploadResult> => {
            setLoading(true);

            const initializeUploadResult = await initializeUpload({
                variables: {
                    input: {
                        category,
                        fileName: file.name,
                        fileType: file.type,
                        size: file.size,
                    },
                },
            });

            if (!initializeUploadResult.data?.uploadInitialize) {
                setLoading(false);
                return null;
            }

            const { signedUrl, upload } = initializeUploadResult.data.uploadInitialize;

            try {
                await uploadToS3(signedUrl, file);

                const uploadSuccessResult = await uploadSuccess({
                    variables: {
                        id: upload.id,
                    },
                });

                if (uploadSuccessResult.data?.uploadSuccess.__typename === 'UploadNotFoundError') {
                    throw new Error('Upload was not found.');
                }
                setLoading(false);

                const fallbackFileUrl = URL.createObjectURL(file);

                return { ...upload, fallbackFileUrl };
            } catch (error) {
                Sentry.captureException(error);
                // eslint-disable-next-line no-console
                console.error(error);
                await uploadFailure({
                    variables: {
                        failedUploadId: upload.id,
                    },
                });
                setLoading(false);
                return null;
            }
        },
        [initializeUpload, uploadFailure, uploadSuccess],
    );

    return { loading, triggerUpload };
}
