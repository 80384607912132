import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';

export type TextFieldProps = Pick<
    MuiTextFieldProps,
    | 'autoComplete'
    | 'autoFocus'
    | 'children'
    | 'className'
    | 'disabled'
    | 'error'
    | 'fullWidth'
    | 'helperText'
    | 'InputLabelProps'
    | 'InputProps'
    | 'inputProps'
    | 'inputRef'
    | 'label'
    | 'maxRows'
    | 'minRows'
    | 'multiline'
    | 'name'
    | 'onBlur'
    | 'onChange'
    | 'onKeyDown'
    | 'placeholder'
    | 'ref'
    | 'required'
    | 'rows'
    | 'select'
    | 'SelectProps'
    | 'size'
    | 'sx'
    | 'type'
    | 'value'
    | 'variant'
>;

export const TextField = (props: TextFieldProps): JSX.Element => <MuiTextField {...props} />;

TextField.displayName = 'TextField';
