/**
 * This function is used in exhaustive checks, to ensure at compile time that all discriminant values in a union are
 * accounted for.
 *
 * e.g.
 *
 * type SomeUnion = "foo" | "bar";
 *
 * function testValue(value : SomeUnion) : void {
 *  switch (value) {
 *    case "foo":
 *      console.log("okay");
 *      break;
 *    default:
 *      assertNever(value); // Fails at compile time, saying 'Unexpected value: "bar"'.
 *  }
 * }
 *
 * See:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 *
 * @param never - shouldn't happen
 */
export function assertNever(never: never): never {
    throw new Error(`Unexpected value: "${never}"`);
}
