import { gql } from '@apollo/client';

export const UPLOAD_SUCCESS_FOR_FILE = gql`
    mutation UploadSuccessForFile($id: ID!) {
        uploadSuccess(id: $id) {
            ... on Upload {
                id
                filename
                url
            }
            ... on UploadNotFoundError {
                code
                message
            }
        }
    }
`;
