import { assertNaturalNumber } from './assert-natural-number';

/**
 *
 * This is the reverse of the pairing function - it takes a single positive integer and outputs the two pairs that were
 * combined to create it. There always exists a unique pairing for every positive integer. Order of the outputs are
 * important.
 *
 * @example
 * fromPairedNumber(1432) // Output: [52, 1]
 * @param value - The integer to find the pair for
 * @returns The two integers that correspond to the given input
 * @see https://en.wikipedia.org/wiki/Pairing_function
 * @see toPairedNumber
 */
export function fromPairedNumber(value: number): [number, number] {
    assertNaturalNumber(value);
    // eslint-disable-next-line id-length
    const w = Math.floor((Math.sqrt(8 * value + 1) - 1) / 2);

    const t = (w * (w + 1)) / 2;
    const y = value - t;
    const x = w - y;
    return [x, y];
}
