import { ChangeEvent } from 'react';

export const radioEnumAssign = <T extends Record<string, string>>(
    event: ChangeEvent<HTMLInputElement>,
    enumObj: T,
): T[keyof T] | null => {
    const value = event.target.value;

    return Object.values(enumObj).includes(value) ? (value as T[keyof T]) : null;
};
