interface BrandBranding<B extends string> {
    __brand: B;
}

type BrandableBase<T = unknown> = Record<string, unknown> | T[] | bigint | number | string | symbol;

/**
 * Brand
 *
 * @summary
 * Brands a ts type with a configurable brand property
 * Branding is the process of adding a special property to a type to make it unique
 * First generic arg is the type to brand, second is the value to brand it with
 * Note: adds the __brand field to the type.  These do not actually exist
 * outside of typescript, so cannot be used by transpiled JS.  This shouldn't come up
 * unless using reflection decorators.
 * @example
 * // Creates the UserId type, separate from a number according to ts
 * // Note that in the transpiled js, there is no difference between a number and UserId
 * // The second generic argument is the "brand" that makes the type unique
 * type UserId = Brand<number, 'UserId'>;
 * @see BaseOf
 * @see asBrand
 * @see createBrander
 */
export type Brand<Base extends BrandableBase, Branding extends string> = BrandBranding<Branding> &
    (Base extends infer BrandBase & {
        __brand: string;
    }
        ? BrandBase
        : Base);

type AnyBrand = Brand<BrandableBase, string>;

/**
 * BaseOf
 *
 * @summary
 * Returns the base type of a BrandedType
 * @example
 * type UserId = Brand<number, "UserId">;
 * // BaseOfUserId will be `number`, since that was the first generic argument of UserId
 * type BaseOfUserId = BaseOf<UserId>;
 */
export type BaseOf<B extends AnyBrand> = B extends unknown[]
    ? [...B] // Is this right?
    : B extends string
    ? string
    : B extends symbol
    ? symbol
    : B extends number
    ? number
    : B extends bigint
    ? bigint
    : B extends Record<string, unknown>
    ? Record<string, unknown>
    : never;

/**
 * Branded
 *
 * @summary
 * An instance of a Branded Type that contains one function: from
 */
export interface Branded<B extends AnyBrand> {
    from: (val: BaseOf<B>) => B;
}

/**
 * CreateBranded
 *
 * @param from - optional function for the from property - if not included, uses default
 * @summary
 * Creates a branded instance of a provided Branded Type
 * @returns - Branded Instance, with one function: from
 * @example
 * type UserId = Brand<number, "UserId">;
 * const UserId = createBranded<UserId>();
 * const myUserId: UserId = UserId.from(123);
 */
export function createBranded<B extends AnyBrand>(from?: (val: BaseOf<B>) => B): Branded<B> {
    return Object.freeze({
        from: from ?? ((val: BaseOf<B>) => val as unknown as B),
    });
}

/*
 ********DEPRECATED********
 */

/**
 * @deprecated - use Branded API instead
 */
export type Brander<B extends AnyBrand> = (initial: BaseOf<B>) => B;

/**
 * Funtion: asBrand
 *
 * @summary
 * Casts a value to the branded type provided in generic argument
 * @param value - The value that you want to brand (must be the base type)
 * @returns The given value cast to the branded type
 * @example
 * type UserId = Brand<number, 'UserId'>;
 *
 * const myUserId: UserId = asBrand<UserId>(5);
 * @deprecated - Use Branded API instead - const UserId = createBranded<UserId>();
 */
export const asBrand = <B extends AnyBrand>(value: BaseOf<B>): B => value as unknown as B;

/**
 * Function: asBase
 *
 * @summary
 * Casts a branded type to its base type
 * @param value - The value, typed as a Brand
 * @returns The same value, but with any branding information (and typing) removed
 * @example
 * type UserId = Brand<number, 'UserId'>;
 * const myUserId = asBrand<UserId>(5);
 *
 * const myRawUserId = asBase(myUserId);
 */
export const asBase = <B extends AnyBrand>(value: B): BaseOf<B> => value as unknown as BaseOf<B>;

/**
 * Funtion: createBrander
 *
 * @summary
 * Creates a brander function, which acts like asBrand but without the generic
 * Takes a generic argument, which is the targetted branded type
 * Basically abstracts the idea of branded type for everyday use
 * @returns A brander function
 * @example
 * type UserId = Brand<number, 'UserId'>;
 * const asUserId = createBrander<UserId>();
 *
 * const myUserId: UserId = asUserId(5);
 * @deprecated - use Branded API instead - const UserId = createBranded<UserId>();
 */
export const createBrander = <B extends AnyBrand>(): Brander<B> => asBrand;
