import {
    AlertCircle,
    AlertTriangle,
    ArrowUp,
    ArrowDown,
    InfoCircle,
    Calendar,
    CheckCircle,
    Clock,
} from '@deltasierra/react/icons';
import { ThemeOptions } from '@mui/material/styles';

export const components: ThemeOptions['components'] = {
    MuiAlert: {
        defaultProps: {
            iconMapping: {
                error: <AlertCircle />,
                info: <InfoCircle />,
                success: <CheckCircle />,
                warning: <AlertTriangle />,
            },
        },
        styleOverrides: {
            filledError: ({ theme }) => ({
                backgroundColor: theme.palette.error.dark,
            }),
            filledInfo: ({ theme }) => ({
                backgroundColor: theme.palette.common.black,
            }),
            root: {
                '& .MuiAlertTitle-root': {
                    paddingTop: '0.5px',
                },
            },
            standardError: ({ theme }) => ({
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.dark,
            }),
            standardInfo: ({ theme }) => ({
                backgroundColor: theme.palette.info.light,
                color: theme.palette.info.main,
            }),
            standardSuccess: ({ theme }) => ({
                backgroundColor: theme.palette.success.light,
                color: theme.palette.success.main,
            }),
            standardWarning: ({ theme }) => ({
                backgroundColor: theme.palette.warning.light,
                color: theme.palette.error.dark,
            }),
        },
    },
    MuiButton: {
        defaultProps: {
            disableElevation: true,
        },
        styleOverrides: {
            root: {
                paddingBottom: '1.125rem',
                paddingTop: '1.125rem',
            },
            sizeLarge: {
                paddingLeft: '1.38rem',
                paddingRight: '1.38rem',
            },
            sizeMedium: {
                paddingLeft: '1rem',
                paddingRight: '1rem',
            },
            sizeSmall: {
                paddingLeft: '0.625rem',
                paddingRight: '0.625rem',
            },
        },
    },
    MuiButtonGroup: {
        defaultProps: {
            disableElevation: true,
        },
    },
    MuiContainer: {
        defaultProps: {
            maxWidth: 'xl',
        },
        styleOverrides: {
            root: ({ theme }) => ({
                [theme.breakpoints.up('sm')]: {
                    '&:not(.MuiContainer-disableGutters)': {
                        paddingLeft: 15,
                        paddingRight: 15,
                    },
                },
            }),
        },
    },
    MuiDateCalendar: {
        styleOverrides: {
            root: {
                borderRadius: '0.5rem',
            },
        },
    },
    MuiDatePicker: {
        defaultProps: {
            fixedWeekNumber: 6,
            reduceAnimations: true,
            showDaysOutsideCurrentMonth: true,
            slotProps: {
                nextIconButton: {
                    style: {
                        height: '1rem',
                        width: '1rem',
                    },
                },
                previousIconButton: {
                    style: {
                        height: '1rem',
                        width: '1rem',
                    },
                },
            },
            slots: {
                nextIconButton: ArrowDown,
                openPickerIcon: Calendar,
                previousIconButton: ArrowUp,
            },
        },
    },
    MuiDayCalendar: {
        styleOverrides: {
            weekDayLabel: {
                fontSize: '1rem',
            },
        },
    },
    MuiFormControl: {
        defaultProps: {
            fullWidth: true,
        },
    },
    MuiPickersArrowSwitcher: {
        styleOverrides: {
            spacer: {
                width: '0.5rem',
            },
        },
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            root: {
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
            },
            switchViewButton: {
                color: 'black',
            },
        },
    },
    MuiPickersDay: {
        styleOverrides: {
            root: {
                '&:not(.Mui-disabled):not(.MuiPickersDay-dayOutsideMonth)': {
                    opacity: 1,
                },
                borderRadius: '0.5rem',
                fontSize: '1rem',
                opacity: 0.38,
            },
        },
    },
    MuiPickersYear: {
        styleOverrides: {
            yearButton: {
                borderRadius: '0.5rem',
                fontSize: '1rem',
                fontWeight: 400,
                width: '4rem',
            },
        },
    },
    MuiTextField: {
        defaultProps: { InputLabelProps: { shrink: true }, autoComplete: 'off' },
        styleOverrides: {
            root: {
                borderRadius: '8px',
            },
        },
    },
    MuiTimePicker: {
        defaultProps: {
            slots: {
                openPickerIcon: Clock,
            },
        },
    },
    MuiTooltip: {
        defaultProps: {
            arrow: true,
        },
        styleOverrides: {
            arrow: {
                color: 'black',
            },
            tooltip: {
                backgroundColor: 'black',
            },
        },
    },
    MuiYearCalendar: {
        styleOverrides: {
            root: {
                paddingLeft: '1rem',
                paddingRight: '1rem',
            },
        },
    },
};
