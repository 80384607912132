import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material';

export type CheckboxProps = Pick<
    MuiCheckboxProps,
    | 'checked'
    | 'checkedIcon'
    | 'disabled'
    | 'disableRipple'
    | 'icon'
    | 'indeterminate'
    | 'inputProps'
    | 'name'
    | 'onChange'
    | 'required'
    | 'size'
    | 'sx'
    | 'value'
>;

export const Checkbox = (props: CheckboxProps): JSX.Element => <MuiCheckbox {...props} />;

Checkbox.displayName = 'Checkbox';
