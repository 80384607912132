import { SVGProps } from 'react';

export const BlockColumns33x66 = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="18" viewBox="0 0 35 18" width="35" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="9.37139" x="1.46497" y="1.36288" />
        <rect height="15" rx="3" stroke="currentColor" strokeWidth="2" width="19.6456" x="13.9122" y="1.36288" />
    </svg>
);

BlockColumns33x66.displayName = 'BlockColumns33x66';
