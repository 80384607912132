import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { useState, ChangeEvent, KeyboardEvent, ClipboardEvent } from 'react';

const invalidCharacters = ['e', 'E', '+'];

// Prevent individual keys from being entered if they're invalid (e.g. 'e')
const onKeyDownHandler = (event: KeyboardEvent) => invalidCharacters.includes(event.key) && event.preventDefault();

// Prevent pasting non-numbers (e.g. '43-23') & scientific notation numbers (e.g. '1e-8')
const onPasteHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData('Text');
    if (isNaN(Number(pastedValue)) || invalidCharacters.some((subString: string) => pastedValue.includes(subString))) {
        event.preventDefault();
    }
};

export type NumberInputProps = Pick<
    MuiTextFieldProps,
    | 'autoComplete'
    | 'disabled'
    | 'error'
    | 'helperText'
    | 'InputProps'
    | 'inputProps'
    | 'label'
    | 'name'
    | 'onBlur'
    | 'onChange'
    | 'placeholder'
    | 'required'
    | 'size'
    | 'sx'
    | 'value'
    | 'variant'
> & { max?: number; min?: number };

export const NumberInput = ({ error, inputProps, max, min, onChange, ...props }: NumberInputProps): JSX.Element => {
    const [inputError, setInputError] = useState(error);

    // Runs any onChange prop function & uses the validity of the field to set inputError
    const onInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
        return setInputError(!event.target.validity.valid);
    };

    return (
        <MuiTextField
            {...props}
            error={error || inputError}
            inputProps={{
                max,
                min,
                ...inputProps,
            }}
            type="number"
            onInput={onInputHandler}
            onKeyDown={onKeyDownHandler}
            onPaste={onPasteHandler}
        />
    );
};

NumberInput.displayName = 'NumberInput';
