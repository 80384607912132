import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material';

export type TypographyProps = Pick<
    MuiTypographyProps,
    | 'align'
    | 'children'
    | 'className'
    | 'color'
    | 'component'
    | 'display'
    | 'gutterBottom'
    | 'noWrap'
    | 'paragraph'
    | 'sx'
    | 'title'
    | 'variant'
>;

export const Typography = (props: TypographyProps): JSX.Element => <MuiTypography {...props} />;

Typography.displayName = 'Typography';
