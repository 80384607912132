import { Divider as MuiDivider, DividerProps as MuiDividerProps } from '@mui/material';

export type DividerProps = Pick<
    MuiDividerProps,
    'children' | 'flexItem' | 'orientation' | 'sx' | 'textAlign' | 'variant'
>;

export const Divider = (props: DividerProps): JSX.Element => <MuiDivider {...props} />;

Divider.displayName = 'Divider';
