import { gql } from '@apollo/client';

export const UPLOAD_FAILURE_FOR_FILE = gql`
    mutation UploadFailureForFile($failedUploadId: ID!) {
        uploadFailure(id: $failedUploadId) {
            ... on Upload {
                id
            }
            ... on UploadNotFoundError {
                code
                message
            }
        }
    }
`;
