import { SanitizedFileName } from './SanitizedFilename';
import { sanitizeFilename } from './sanitizeFilename';
import { getFileNameFromPath } from './getFilenameFromPath';

export const DEFAULT_FILE_NAME = SanitizedFileName.from('File_1.ext');

// Manually strip our favourite chars
// eslint-disable-next-line no-control-regex
const FILE_NAME_CHARACTERS_TO_SANITIZE = /[&$\x00-\x1F\x7F-\xFF@=;:+\s,?\\{^}%`\]'">[~<#|]/g;

// ISO-8859 Chars need to be stripped from file names or they can throw errors in HTML requests

// eslint-disable-next-line no-control-regex
const ISO_8859_INVALID_CHARS = /[^\u0000-\u00ff]/g;

export function sanitizeFileNameForUpload(fileNameOrPath: string): SanitizedFileName {
    // We can sometimes pass an empty string, in those cases return the default
    if (fileNameOrPath) {
        // Get only the filename from a path
        const filename = getFileNameFromPath(fileNameOrPath);

        const result = filename
            // Trim the string for safety
            .trim()
            // Replace our manually specified chars with underscores
            .replace(FILE_NAME_CHARACTERS_TO_SANITIZE, '_')
            // We can just replace invalid chars with nothing
            .replace(ISO_8859_INVALID_CHARS, '');

        // Run through the sanitize filename function for anything we missed
        const sanitized = sanitizeFilename(result, '_');
        if (sanitized) {
            return SanitizedFileName.from(sanitized);
        }
    }
    return DEFAULT_FILE_NAME;
}
