import {
    DateTimePicker as MuiDateTimePicker,
    DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { DateTime } from 'luxon';

export type DateTimePickerProps = Pick<
    MuiDateTimePickerProps<DateTime>,
    | 'disabled'
    | 'disableFuture'
    | 'disablePast'
    | 'format'
    | 'inputRef'
    | 'label'
    | 'maxDate'
    | 'minDate'
    | 'name'
    | 'onChange'
    | 'onError'
    | 'openTo'
    | 'slotProps'
    | 'sx'
    | 'value'
>;

export const DateTimePicker = (props: DateTimePickerProps): JSX.Element => (
    <MuiDateTimePicker format="dd/MM/yyyy HH:mm" {...props} />
);

DateTimePicker.displayName = 'DateTimePicker';
