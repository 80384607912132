/**
 * @param str - The text to truncate
 * @param maxLength - The max length of the string to display before truncated version is returned
 * @param [ellipsis] - The text to truncate
 * @param [beginningLength] - The length to display before truncation in the case of middle
 * @param [side] - Which side to truncate, start , end or truncate the middle portion of the text.
 * @returns - The truncated string with the optional prefix and/or suffix included in the max length
 */
type TruncateOptions = {
    ellipsis?: string;
    beginningLength?: number;
    side?: 'end' | 'middle' | 'start';
};
export const truncate = (
    str: string,
    maxLength: number,
    { beginningLength = 16, ellipsis = '...', side = 'end' }: TruncateOptions = {},
): string => {
    if (str.length > maxLength) {
        switch (side) {
            // Example output:  ... ORD
            case 'start':
                return ellipsis + str.slice(-(maxLength - ellipsis.length));
            // Example output: W...RD
            case 'middle': {
                const wordSplit: number = Math.floor(beginningLength / 2);
                return str.slice(0, wordSplit) + ellipsis + str.slice(-wordSplit);
            }
            // Example output: WOR ...
            case 'end':
            default:
                return str.slice(0, maxLength - ellipsis.length).trim() + ellipsis;
        }
    }
    return str;
};
