import { ThemeOptions } from '@mui/material/styles';
import { galanoGrotesque, montserrat } from '@deltasierra/react/fonts';

export const typography: ThemeOptions['typography'] = {
    body1: {
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0.00938rem',
        lineHeight: '1.5rem',
    },
    body2: {
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0.01063rem',
        lineHeight: '1.25125rem',
    },
    button: {
        fontFamily: montserrat.style.fontFamily,
        fontSize: '0.75rem',
        fontWeight: 500,
        letterSpacing: '0.025rem',
        lineHeight: '1.245rem',
        textTransform: 'none',
    },
    fontFamily: galanoGrotesque.style.fontFamily,
    h1: {
        fontSize: '2rem',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '-0.09375rem',
        lineHeight: '3rem',
    },
    h2: {
        fontSize: '1.75rem',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '-0.03125rem',
        lineHeight: '2.625rem',
    },
    h3: {
        fontSize: '1.25rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '1.875rem',
    },
    h4: {
        fontSize: '1.125rem',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0.01563rem',
        lineHeight: '1.6875rem',
    },
    h5: {
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '1.5rem',
    },
    h6: {
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.00938rem',
        lineHeight: '1.5rem',
    },
    htmlFontSize: 16,
    subtitle1: {
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 700,
        letterSpacing: '0.00938rem',
        lineHeight: '1.5rem',
    },
    subtitle2: {
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '0.00625rem',
        lineHeight: '1.5rem',
    },
};
