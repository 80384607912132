import {
    CircularProgress as MuiCircularProgress,
    CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material';

export type CircularProgressProps = Pick<
    MuiCircularProgressProps,
    'aria-label' | 'className' | 'color' | 'size' | 'sx' | 'value' | 'variant'
>;

export const CircularProgress = (props: CircularProgressProps): JSX.Element => <MuiCircularProgress {...props} />;

CircularProgress.displayName = 'CircularProgress';
