/**
 * Pick key from an object.
 *
 * @example
 * pick({ name: 'Frank' }, 'name') // { name: 'Frank' }
 * pick({ name: 'Frank' }, 'name', 'age') // { name: 'Frank' }
 * @param obj - The object to pick the keys from
 * @param keys - The keys to pick from the object
 * @returns All the keys picked from the object (missing keys are skipped)
 */
export function pick<TObject, TKeys extends ReadonlyArray<keyof TObject>>(
    obj: TObject,
    ...keys: TKeys
): Pick<TObject, TKeys[number]>;
/**
 * Pick keys from an object and default missing keys to undefined.
 *
 * @deprecated Argument order isn't hard to use and default to undefined is confusing and not clear
 * @example
 * pick(['name'], { name: 'Frank' }) // { name: 'Frank' }
 * pick(['name', 'age'], { name: 'Frank' }) // { name: 'Frank', age: undefined }
 * @param keys - The keys to pick from the object
 * @param obj - The object to pick from
 * @returns All the keys picked from the object. If a key is not found it will be set to undefined
 */
export function pick<TObject, TKeys extends ReadonlyArray<keyof TObject>>(
    keys: TKeys,
    obj: TObject,
): Pick<TObject, TKeys[number]>;
export function pick<TObject, TKeys extends ReadonlyArray<keyof TObject>>(
    ...args: [TKeys, TObject] | [TObject, ...TKeys]
): Pick<TObject, TKeys[number]> {
    const isDeprecatedArgs = (val: [TKeys, TObject] | [TObject, ...TKeys]): val is [TKeys, TObject] =>
        Array.isArray(val[0]);
    if (isDeprecatedArgs(args)) {
        // Old incorrect behavior
        const [keys, obj] = args;
        const result: any = {};
        for (const key of keys) {
            result[key] = obj[key];
        }
        return result;
    } else {
        // New and improved logic
        const [obj, ...keys] = args;
        const result: any = {};
        for (const key of keys) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                result[key] = obj[key];
            }
        }
        return result;
    }
}
