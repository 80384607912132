import { Buffer } from 'buffer';

function isHighSurrogate(codePoint: number) {
    return codePoint >= 0xd800 && codePoint <= 0xdbff;
}

function isLowSurrogate(codePoint: number) {
    return codePoint >= 0xdc00 && codePoint <= 0xdfff;
}

/**
 * Truncates the string to a length in bytes
 *
 * @param str - The string
 * @param byteLength - The length
 * @returns - The truncated string
 */
export function truncateUtf8Bytes(str: string, byteLength: number): string {
    if (typeof str !== 'string') {
        throw new Error('Input must be string');
    }

    const charLength = str.length;
    let curByteLength = 0;
    let codePoint;
    let segment;

    for (let i = 0; i < charLength; i += 1) {
        codePoint = str.charCodeAt(i);
        segment = str[i];

        if (isHighSurrogate(codePoint) && isLowSurrogate(str.charCodeAt(i + 1))) {
            i += 1;
            segment += str[i];
        }

        curByteLength += Buffer.byteLength(segment);

        if (curByteLength === byteLength) {
            return str.slice(0, i + 1);
        } else if (curByteLength > byteLength) {
            return str.slice(0, i - segment.length + 1);
        }
    }

    return str;
}
