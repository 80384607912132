import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material';

export type RadioProps = Pick<
    MuiRadioProps,
    'checked' | 'disabled' | 'inputRef' | 'name' | 'onChange' | 'required' | 'size' | 'value'
>;

export const Radio = (props: RadioProps): JSX.Element => <MuiRadio {...props} />;

Radio.displayName = 'Radio';
