export const PlatformIconPaths = {
    all: '/logos/platform-all.svg',
    campaignMonitor: '/logos/campaign-monitor.svg',
    clubReady: '/logos/club-ready.svg',
    emailCampaigns: '/logos/email-campaigns.svg',
    facebook: '/logos/facebook.svg',
    facebookMarketing: '/logos/facebook-marketing.svg',
    fitware: '/logos/fitware.svg',
    four51: '/logos/four51.svg',
    google: '/logos/google.svg',
    googleAdWords: '/logos/google-ad-words.svg',
    googleMyBusiness: '/logos/google-my-business.svg',
    instagram: '/logos/instagram.svg',
    linkedIn: '/logos/linked-in.svg',
    mailChimp: '/logos/mailchimp.svg',
    print: '/logos/print.svg',
    sendgrid: '/logos/send-grid.svg',
    tiktok: '/logos/tiktok.svg',
    tv: '/logos/tv.svg',
    twitter: '/logos/twitter.svg',
    video: '/logos/video.svg',
    website: '/logos/website.svg',
} as const;