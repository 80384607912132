import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';

export type SwitchProps = Pick<
    MuiSwitchProps,
    'checked' | 'disabled' | 'inputProps' | 'name' | 'onChange' | 'required' | 'size' | 'value'
>;

export const Switch = (props: SwitchProps): JSX.Element => <MuiSwitch {...props} />;

Switch.displayName = 'Switch';
