import { Dimensions, ThumbnailWidth } from '../types';

function scaledWidth({ height, width }: Dimensions, minContainerHeight: number, minContainerWidth: number): number {
    const newWidth = (width / height) * minContainerHeight;
    return newWidth > minContainerWidth ? newWidth : minContainerWidth;
}

export type UseFluidGridOptions = {
    aspectRatios: Dimensions[];
    itemMinWidth: number;
    rowMaxHeight: number;
    rowMinHeight: number;
    rowWidth: number;
    spacing: number;
};

// eslint-disable-next-line max-statements
export const useFluidGrid = ({
    aspectRatios,
    itemMinWidth,
    rowMaxHeight,
    rowMinHeight,
    rowWidth,
    spacing,
}: UseFluidGridOptions): Dimensions[] => {
    const calculatedThumbnailDimensions: Dimensions[] = [];
    const rows: ThumbnailWidth[][] = [];

    let runningRowWidth = 0;
    let lastIndex = 0;

    const guessedWidths: ThumbnailWidth[] = aspectRatios.map(thumbnailAspectRatio =>
        scaledWidth(thumbnailAspectRatio, rowMinHeight, itemMinWidth),
    );

    for (let index = 0; index < guessedWidths.length; index++) {
        const thumbnailWidth = guessedWidths[index];
        runningRowWidth += thumbnailWidth + spacing;
        if (runningRowWidth > rowWidth) {
            rows.push(guessedWidths.slice(lastIndex, index));
            lastIndex = index;
            runningRowWidth = thumbnailWidth + spacing;
        }
    }
    rows.push(guessedWidths.slice(lastIndex));

    for (const row of rows) {
        const currentRowWidth = row.reduce((accumulator, thumbnailWidth) => accumulator + thumbnailWidth, 0);
        const targetRowWidth = rowWidth - (row.length - 1) * spacing;
        const multiplier = targetRowWidth / currentRowWidth;

        for (const thumbnailWidth of row) {
            const containerFilledWidth = thumbnailWidth * multiplier;
            const containerFilledHeight = (containerFilledWidth * rowMinHeight) / thumbnailWidth;

            calculatedThumbnailDimensions.push({
                height: Math.min(containerFilledHeight, rowMaxHeight),
                width: containerFilledWidth,
            });
        }
    }

    return calculatedThumbnailDimensions;
};
